.btn-primary {
    --bs-btn-color: #fff;
    --bs-btn-bg: #8540f5;
    --bs-btn-border-color: #8540f5;
    --bs-btn-hover-color: #fff;
    --bs-btn-hover-bg: #7136d0;
    --bs-btn-hover-border-color: #6a33c4;
    --bs-btn-focus-shadow-rgb: 151, 93, 247;
    --bs-btn-active-color: #fff;
    --bs-btn-active-bg: #6a33c4;
    --bs-btn-active-border-color: #6430b8;
    --bs-btn-active-shadow: inset 0 3px 5px rgba(0, 0, 0, 0.125);
    --bs-btn-disabled-color: #fff;
    --bs-btn-disabled-bg: #8540f5;
    --bs-btn-disabled-border-color: #8540f5;
}

.btn-outline-primary {
    --bs-btn-bg: #fff;
    --bs-btn-color: #8540f5;
    --bs-btn-border-color: #8540f5;
    --bs-btn-hover-color: #fff;
    --bs-btn-hover-bg: #8540f5;
    --bs-btn-hover-border-color: #8540f5;
    --bs-btn-focus-shadow-rgb: 133, 64, 245;
    --bs-btn-active-color: #fff;
    --bs-btn-active-bg: #8540f5;
    --bs-btn-active-border-color: #8540f5;
    --bs-btn-active-shadow: inset 0 3px 5px rgba(0, 0, 0, 0.125);
    --bs-btn-disabled-color: #8540f5;
    --bs-btn-disabled-bg: transparent;
    --bs-btn-disabled-border-color: #8540f5;
    --bs-gradient: none;
}

.btn-secondary {
    --bs-btn-color: #fff;
    --bs-btn-bg: #052c65;
    --bs-btn-border-color: #052c65;
    --bs-btn-hover-color: #fff;
    --bs-btn-hover-bg: #042556;
    --bs-btn-hover-border-color: #042351;
    --bs-btn-focus-shadow-rgb: 43, 76, 124;
    --bs-btn-active-color: #fff;
    --bs-btn-active-bg: #042351;
    --bs-btn-active-border-color: #04214c;
    --bs-btn-active-shadow: inset 0 3px 5px rgba(0, 0, 0, 0.125);
    --bs-btn-disabled-color: #fff;
    --bs-btn-disabled-bg: #052c65;
    --bs-btn-disabled-border-color: #052c65;
}

.btn-outline-secondary {
    --bs-btn-color: #052c65;
    --bs-btn-border-color: #052c65;
    --bs-btn-hover-color: #fff;
    --bs-btn-hover-bg: #052c65;
    --bs-btn-hover-border-color: #052c65;
    --bs-btn-focus-shadow-rgb: 5, 44, 101;
    --bs-btn-active-color: #fff;
    --bs-btn-active-bg: #052c65;
    --bs-btn-active-border-color: #052c65;
    --bs-btn-active-shadow: inset 0 3px 5px rgba(0, 0, 0, 0.125);
    --bs-btn-disabled-color: #052c65;
    --bs-btn-disabled-bg: transparent;
    --bs-btn-disabled-border-color: #052c65;
    --bs-gradient: none;
}

.form-control:focus, .form-select:focus {
    border-color: #c29ffa;
    box-shadow: 0 0 0 0.25rem rgb(133 64 245 / 25%);
}

.navbar-dark .navbar-nav .nav-link:not(.active) {
    color: rgba(255,255,255,.75)
}

.nav-pills {
    --bs-nav-pills-link-active-color: #fff;
    --bs-nav-pills-link-active-bg: #3730a3;
    --bs-nav-link-color: #3730a3;
    --bs-nav-link-hover-color: #312e81;
}