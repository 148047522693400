.title::after {
  position: absolute;
  content: "";
  width: 35%;
  z-index: 1;
  top: 0px;
  right: 0px;
  height: 100%;
  background-size: 18px 18px;
  background-image: radial-gradient(rgb(13, 71, 161) 20%, transparent 20%);
  opacity: 0.1;
}

.screenshot {
    width: 350px;
    height: 613.625px;
    border: solid;
    border-radius: 16px;
    border-color: #e0cffc;
    border-width: 4px;
}

.product-screenshot {
    width: 100%;
    border: solid;
    border-radius: 16px;
    border-color: #e0cffc;
    border-width: 4px;
}

.section {
    background-color: #f3f6f9;
}