@import url('https://fonts.googleapis.com/css2?family=Open+Sans:wght@300;400;500;600;700&display=swap');

:root {
    --scrollbar-radius: 5px;

    --app-nav-bar-color: var(--tw-violet-900);
    --app-nav-bar-user-color: var(--tw-violet-950);

    --app-tabs-color: var(--tw-violet-900); 
}

body {
    font-family: 'Open Sans';
}
  
::-webkit-scrollbar {
    width: 6px;
    height: 6px;
}

::-webkit-scrollbar-track {
    -webkit-border-radius: var(--scrollbar-radius);
    border-radius: var(--scrollbar-radius);
    background: rgba(155, 161, 171, .1);
}

/* Handle */
::-webkit-scrollbar-thumb {
    -webkit-border-radius: var(--scrollbar-radius);
    border-radius: var(--scrollbar-radius);

    background: rgba(155, 161, 171, .5);
}

button:disabled {
    cursor: not-allowed;
    pointer-events: all !important;
  }

*:disabled {
    cursor: not-allowed;
    pointer-events: all !important;   
}

.contrast-shadow {
    box-shadow: rgb(0 0 0 / 8%) 0px 0px 8px;
  }
  
.contrast-shadow-sm {
    box-shadow: rgb(0 0 0 / 8%) 0px 0px 4px;
}

/* Chrome, Safari, Edge, Opera */
input::-webkit-outer-spin-button,
input::-webkit-inner-spin-button {
    -webkit-appearance: none;
    margin: 0;
}

/* Firefox */
input[type=number] {
    -moz-appearance: textfield;
}