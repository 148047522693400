.tagInput {
    border-top-right-radius: 0px;
    border-bottom-right-radius: 0px;
}

.addTag {
    border-top-left-radius: 0px;
    border-bottom-left-radius: 0px;
}

.listItem {
    padding-top: .75rem;
    padding-bottom: .75rem;
}