.eventChart {
    overflow: auto;

    display: flex;
    justify-content: center;
    
    max-height: 350px;
}

.chart {
    gap: 4px;
    row-gap: 6px;
    flex-wrap: wrap;
}

.day {
    width: 12px;
    aspect-ratio: 1 / 1;

    background-color: #fff;
    border: 1px solid transparent;
    border-top-color: transparent;
    border-right-color: transparent;
    border-bottom-color: transparent;
    border-left-color: transparent;

    border-color: #2f54eb;
    background-color: #e6f7ff;
    border-radius: 2px;

    flex-grow: .25;
}

.filteredDay {
    background-color: #1d39c4;
    border-color: #10239e;
}

.dayContainer:hover {
    z-index: 100000;
}

.day:hover {
    background-color: #9e1068;
    z-index: 10000;
}

.line {
    max-width: 90px;
    border-top: 1px solid rgb(189, 189, 189);
    border-top-color: rgb(189, 189, 189);
    border-color: rgb(189, 189, 189);
    flex-grow: 1;
}

.label {
    font-size: 10px;
    margin-top: 2px;
}

.event {
    position: relative;
    bottom: 2px;
    border-left: dashed 1px;
    left: 50%;
    overflow: visible;
}

.noEvent, .event {
    height: 80px;
}

.line, .event, .label {
    width: 12px;
}

.eventLabel {
    font-size: 13px;
    max-width: 140px;
    width: fit-content;
}

.eventLabel:hover, .eventLabelDate:hover, .dayContainer .eventLabel:hover, .dayContainer:hover .eventLabel, .dayContainer:hover .eventLabelDate {
    color: #212529;
    background-color: #fff;
    border-color: #86b7fe;
    outline: 0;
    box-shadow: 0 0 0 0.25rem rgb(13 110 253 / 25%);

    max-width: 500px;
    z-index: 10000;    
}

.eventLabelDate {
    font-size: 10px;
    width: fit-content;
    background-color: #fff;
}

.eventLabelDate:hover {
    color: #212529;
    background-color: #fff;
    border-color: #86b7fe;
    outline: 0;
    box-shadow: 0 0 0 0.25rem rgb(13 110 253 / 25%);

    max-width: 500px;
    z-index: 10000;
    
    display: block;
}

.sleepLogRowTooltip {
    --bs-popover-max-width: min(100%, 1000px);
    --bs-popover-zindex: 9999;
}