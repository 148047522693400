.username {
    --bs-btn-border-width: 0px;
    --bs-btn-color: rgba(255,255,255,.75);
    --bs-btn-hover-color: rgba(255,255,255,1);
    --bs-btn-active-color: rgba(255,255,255,1);
}

.disabledLink {
    color: gray;
    cursor: not-allowed;
    pointer-events: none;
    text-decoration: none;
    font-style: italic;
  }