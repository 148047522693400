.sleep-log-modal .modal-content{
  height: 100vh;
}

.btn-sleep-table {
    --bs-btn-bg: var(--tw-blue-50);
    --bs-btn-border-color: var(--tw-blue-300);
    --bs-btn-hover-bg: var(--tw-blue-100);
    --bs-btn-hover-border-color: var(--tw-blue-300);
    --bs-btn-focus-shadow-rgb: 151, 93, 247;
    --bs-btn-active-bg: var(--tw-blue-100);
    --bs-btn-active-border-color: var(--tw-blue-300);
    --bs-btn-active-shadow: inset 0 3px 5px rgba(0, 0, 0, 0.125);
    --bs-btn-disabled-color: #000f;
    --bs-btn-disabled-bg: var(--tw-blue-50);
    --bs-btn-disabled-border-color: var(--tw-blue-300);
    --bs-btn-color: #000;
    --bs-btn-hover-color: #000;
    --bs-btn-active-color: #000;
}


.btn-create {
  color: #1d39c4;
  border-color: #1d39c4;
}
.btn-create:hover {
  color: #fff;
  background-color: #1d39c4;
  border-color: #1d39c4;
}
.btn-check:focus + .btn-create, .btn-create:focus {
  box-shadow: 0 0 0 0.25rem rgba(29, 57, 196, 0.5);
}
.btn-check:checked + .btn-create, .btn-check:active + .btn-create, .btn-create:active, .btn-create.active, .btn-create.dropdown-toggle.show {
  color: #fff;
  background-color: #1d39c4;
  border-color: #1d39c4;
}
.btn-check:checked + .btn-create:focus, .btn-check:active + .btn-create:focus, .btn-create:active:focus, .btn-create.active:focus, .btn-create.dropdown-toggle.show:focus {
  box-shadow: 0 0 0 0.25rem rgba(29, 57, 196, 0.5);
}
.btn-create:disabled, .btn-create.disabled {
  color: #1d39c4;
  background-color: transparent;
}