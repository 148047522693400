.app-bg-light {
    --app-bg-color: var(--tw-slate-50);
}

.app-bg-white {
    --app-bg-color: rgb(255, 255, 255);
}

.app-page {
    background-color: var(--app-bg-color);
    min-height: 100vh;
}