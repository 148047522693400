.hoverable-suggestion:hover {
  background-color: #f2f2f2;
}

.selected-suggestion {
  background-color: #c9c9c9;
}

.favorite-button {
  cursor: pointer
}

.underline-input {
  outline: 0;
  border-width: 0 0 1px;
  border-color: darkslategray;
}
.underline-input:focus {
  border-width: 0 0 1.5px;
  border-color: darkviolet;
}