.sleep-log-row, .sleep-log-row-tags {
  height: 80px;
  margin-top: 8px;
  margin-bottom: 8px;
  position: relative;
  overflow-y: auto;
}

.sleep-log-row-tags {
  min-height: 40px;
  max-height: 140px;
  height: auto;
}

.sleep-episodes {
    height: 50px;
    overflow-y: auto;    
}

@media (min-width: 992px) {
  .sleep-log-row, .sleep-log-row-tags {
    height: 140px;
  }

  .sleep-episodes {
    height: 100px;
  }
}

.sleep-medication-tag {
  background-color: #0d6efd;
  max-width: 100px;
}

.other-medication-tag {
  background-color: #8540f5;
}

.sleep-log-tag {
  background-color: #087990;
}

.feeling-tag {
  background-color: #e35d6a;
}

.ant-tag {
  color: #000000d9;
  font-weight: 500;
  font-variant: tabular-nums;
  line-height: 1.5715;
  list-style: none;
  font-feature-settings: "tnum";
  display: inline-block;
  height: auto;
  padding: 1px 7px;
  font-size: .875rem;
  white-space: nowrap;
  background: #fafafa;
  border: 1px solid #d9d9d9;
  border-radius: 2px;
  opacity: 1;
  height: fit-content;
  transition: all .3s;
}

.ant-tag-geek-blue-dark {
  color: black;
  background: #f0f5ff;
  border-color: #d6e4ff;
}

.ant-tag-magenta {
  color: black; 
  background: #fff0f6;
  border-color: #ffd6e7;
}

.ant-tag-red {
  color: black;
  background: #fff1f0;
  border-color: #ffa39e;
}

.ant-tag-purple {
  color: black;
  background: #f9f0ff;
  border-color: #efdbff;
}

.ant-tag-volcano {
  color: black;
  background: #fff2e8;
  border-color: #ffbb96;
}

.ant-tag-orange {
  color: #612500;
  background: #fff7e6;
  border-color: #ffe7ba;
}

.ant-tag-gold {
  color: #d48806;
  background: #fffbe6;
  border-color: #ffe58f;
}

.ant-tag-blue {
  color: black;
  background: #e6f7ff;
  border-color: #bae7ff;
}

.ant-tag-lime {
  color: #254000;
  background: #fcffe6;
  border-color: #d3f261;
}

.ant-tag-lime {
  color: black;
  background: #d1fae5;
  border-color: #34d399;
  font-weight: 600;
}

.sleep-log-select {
    background-size: 13px 9.75px;
    background-position: right .15rem center;    
}

.sleep-log-rating {
    border-width: 3px;
    cursor: pointer;
    width: auto;
    padding-left: 0.5rem;
    padding-right: 1rem;
}

.custom-metric-rating {
    border-width: 1px;
    cursor: pointer;
    width: 3rem;
    padding-left: 0.5rem;
    padding-right: 1rem;
    padding-top: 0;
    padding-bottom: 0;    
}

.custom-metric-number {
    border-radius: .25rem;
    width: 3rem;
    padding-top: 0;
    padding-bottom: 0;
    padding-left: .5rem;
    padding-right: 0rem;
}