.cardAccent {
    /* border-left-width: 6px; */
    border-top: 0px;
    border-left: 0px;
    border-right: 0px;
    border-bottom: 0px;
}

.sleepAccent {
    border-color: #8b5cf6;
}

.ratingAccent {
    border-color: #f59e0b;
}

.sleepTimeAccent {
    border-color: #6366f1;
}


.wakeTimeAccent {
    border-color: #f43f5e;
}