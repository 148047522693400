.flex-gap-1 {
    gap: .25rem;
}

.flex-gap-2 {
    gap: .5rem;
}

.flex-gap-3 {
    gap: .75rem;
}

.flex-gap-4 {
    gap: 1rem;
}

.flex-gap-5 {
    gap: 1.5rem;
}

.dragging {
    cursor: grabbing;
}

.text-sm {
    font-size: .9375rem;
}

.text-xs {
    font-size: .875rem;
}

.text-xxs {
    font-size: .8125rem;
}

.text-muted-light {
    color: #00000078;
}

.input-underline {
    border-radius: 0px;
    border-width: 0px 0px 1px 0px;
}

.input-underline:focus {
    box-shadow: none;
    border-bottom-width: 1px;
}