/* Styles derived from Tailwind CSS */

:root {
    --tw-slate-50: #f8fafc;

    --tw-gray-500: #6b7280;
    --tw-gray-600: #4b5563;

    --tw-blue-50: #eef2ff;
    --tw-blue-100: #dbeafe;
    --tw-blue-200: #bfdbfe;
    --tw-blue-300: #a5b4fc;
    --tw-blue-400:#60a5fa;
    --tw-blue-500: #6366f1;
    --tw-blue-600: #4f46e5;

    --tw-violet-800: #5b21b6;
    --tw-violet-900: #4c1d95;
    --tw-violet-950: #2e1065;

    --tw-ring-inset: ;
    --tw-ring-offset-width: 0px;
    --tw-ring-offset-color: #fff;
}

.text-gray-500 {
    color: var(--tw-gray-500);
}

.text-gray-600 {
    color: var(--tw-gray-600);
}

.ring-gray-200 {
    --tw-ring-opacity: 1 !important;
    --tw-ring-color: rgb(229 231 235/var(--tw-ring-opacity)) !important;
}

.ring-1 {
    --tw-ring-offset-shadow: var(--tw-ring-inset) 0 0 0 var(--tw-ring-offset-width) var(--tw-ring-offset-color) !important;
    --tw-ring-shadow: var(--tw-ring-inset) 0 0 0 calc(1px + var(--tw-ring-offset-width)) var(--tw-ring-color) !important;
}

.ring, .ring-1 {
    box-shadow: var(--tw-ring-offset-shadow),var(--tw-ring-shadow),var(--tw-shadow,0 0 #0000) !important;
}

.tw-shadow {
    --tw-shadow: 0 1px 3px 0 rgba(0,0,0,.1),0 1px 2px -1px rgba(0,0,0,.1) !important;
    --tw-shadow-colored: 0 1px 3px 0 var(--tw-shadow-color),0 1px 2px -1px var(--tw-shadow-color) !important;
}

.tw-shadow {
    box-shadow: var(--tw-ring-offset-shadow,0 0 #0000),var(--tw-ring-shadow,0 0 #0000),var(--tw-shadow);
}

.tw-shadow-md {
    --tw-shadow: 0 4px 6px -1px #0000001a,0 2px 4px -2px #0000001a;
    --tw-shadow-colored: 0 4px 6px -1px var(--tw-shadow-color),0 2px 4px -2px var(--tw-shadow-color);
  }

.tw-shadow-lg, .tw-shadow-md {
    box-shadow: var(--tw-ring-offset-shadow,0 0 #0000),var(--tw-ring-shadow,0 0 #0000),var(--tw-shadow);
}